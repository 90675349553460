import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import ReactHtmlParser from 'react-html-parser';
import cachios from 'cachios'
import { BASEURL, BASE } from "../api/api";
import Header from '../header/header'
import Footer from '../footer/footer'
import './home.css'
import dealimage from '../assets/Deals analysed.png'
import interestimg from '../assets/Interested Investors.png'
import activeimg from '../assets/Active investors.png'
import businessimg from '../assets/Businesses funded.png'
import croreimg from '../assets/Crores Raised.png'
import Slider from "react-slick";
import quote from '../assets/quote-watermark.png'
import activeleft from '../assets/Active left.png'
import deactiveleft from '../assets/De active left.png'
import activeright from '../assets/active right.png'
import deactiveright from '../assets/De active right.png'
import dummy from '../assets/dummyimg.jpg'
import white from '../assets/white.png'
import hometitle from '../assets/Main-image.png'
import homedesktitle from '../assets/rehbar-hera.png'
import MagicSliderDots from 'react-magic-slider-dots';
import './magic-dots.css';
import "../styles/typography.css"
import "../styles/elements.css"
import "../styles/styles.scss"

let imagearray = []
let logoarray = []


class HomePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            width: window.innerWidth,
            hometitle: '',
            button1: '',
            button2: '',
            testimonial: '',
            testindex: 0,
            testlen: '',
            html: '',
            homedesc: '',
            slider: '',
            testlogo: false,
            testimg: false
        }

    }

    testimonial() {


        cachios.get(BASEURL + '/jsonapi/node/testimonial?include=field_dp', {
            ttl: 300 /* seconds */,
        })
            .then((data) => {
                let i, j;

                this.setState({
                    testimonial: data.data.data.reverse(),
                    testlen: data.data.data.length,
                })
                this.setState({
                    testimg: true
                })


                for (i = 0; i < data.data.data.length; i++) {

                    if (data.data.data[i].relationships.field_dp.data !== null) {
                        for (j = 0; j < data.data.included.length; j++) {
                            if (data.data.data[i].relationships.field_dp.data.id === data.data.included[j].id) {
                                imagearray.push(BASE + data.data.included[j].attributes.uri.url)
                            }
                        }
                    }
                    else {
                        imagearray.push(dummy)
                    }

                }
                this.setState({
                    imagearray: imagearray
                })

                if (imagearray.length === data.data.data.length) {
                    this.setState({
                        testimg: true
                    })
                }




            })

            .catch((err) => {
                console.log('not found')
            })

        //testimonLogo

        cachios.get(BASEURL + '/jsonapi/node/testimonial?include=field_company_logo', {
            ttl: 300 /* seconds */,
        })
            .then((data) => {
                let i, j;
                this.setState({
                    testlogo: true
                })



                for (i = 0; i < data.data.data.length; i++) {

                    if (data.data.data[i].relationships.field_company_logo.data !== null) {
                        for (j = 0; j < data.data.included.length; j++) {
                            if (data.data.data[i].relationships.field_company_logo.data.id === data.data.included[j].id) {
                                logoarray.push(BASE + data.data.included[j].attributes.uri.url)
                            }
                        }
                    }
                    else {
                        logoarray.push(white)
                    }

                }
                this.setState({
                    logoarray: logoarray.reverse()
                })
                if (logoarray.length === data.data.data.length) {
                    this.setState({
                        testlogo: true
                    })
                }




            })
            .catch((err) => {
                console.log('not found')
            })


    }
    componentWillMount() {
        document.getElementsByTagName("BODY")[0].style.overflow = "auto";
        cachios.get(BASEURL + '/jsonapi/block_content/description_image?include=field_image', {
            ttl: 300 /* seconds */,
        })
            .then((data) => {
                this.setState({
                    // hometitle:BASE+data.data.included[0].attributes.uri.url,
                    homedesc: data.data.data[0].attributes.field_description[0].value
                })
            })
            .catch((err) => {
                console.log('not found')
            })

        //button


        cachios.get(BASEURL + '/jsonapi/block_content/ti', {
            ttl: 300 /* seconds */,
        })
            .then((data) => {

                this.setState({
                    button1: data.data.data[1].attributes,
                    button2: data.data.data[0].attributes
                })
            })
            .catch((err) => {
                console.log('not found')
            })

        //testimonialfield_image
        this.testimonial()

        //sliderCompleted


        cachios.get(BASEURL + '/jsonapi/node/deals?filter[field_status]=completed&include=field_logo', {
            ttl: 300 /* seconds */,
        })
            .then((data) => {
                this.setState({
                    slidercomp: data.data.included
                })
            })
            .catch((err) => {
                console.log('not found')
            })

        //sliderProposed

        cachios.get(BASEURL + '/jsonapi/node/deals?filter[field_status]=proposed&include=field_logo', {
            ttl: 300 /* seconds */,
        })
            .then((data) => {
                this.setState({
                    sliderprop: data.data.included
                })
            })
            .catch((err) => {
                console.log('not found')
            })
    }

    testindex(id) {

        if (id === 1) {
            if (this.state.testindex !== 0) {
                this.setState({
                    testindex: this.state.testindex - 1
                })

            }
            else {
                this.setState({
                    testindex: 0
                })
            }

        }

        else {
            if (this.state.testlen - 1 !== this.state.testindex) {
                this.setState({
                    testindex: this.state.testindex + 1
                })
            }
            else {
                this.setState({
                    testindex: this.state.testlen - 1
                })
            }
        }
    }



    render() {

        const settings = {
            appendDots: (dots) => {
                if (this.state.width < 768)
                    return <MagicSliderDots dots={dots} numDotsToShow={5} dotWidth={30} />
                else
                    return <MagicSliderDots dots={dots} numDotsToShow={this.state.slidercomp.length} dotWidth={30} />
            },
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: this.state.slidercomp ? this.state.slidercomp.length <= 3 ? this.state.slidercomp.length : this.state.width > 768 ? 4 : 2 : 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 1500
        };

        const recsettings = {
            appendDots: (dots) => {
                if (this.state.width < 768)
                    return <MagicSliderDots dots={dots} numDotsToShow={5} dotWidth={30} />
                else
                    return <MagicSliderDots dots={dots} numDotsToShow={this.state.sliderprop.length} dotWidth={30} />
            },
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: this.state.sliderprop ? this.state.sliderprop.length <= 3 ? this.state.sliderprop.length : this.state.width > 768 ? 4 : 2 : 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 1500
        };

        return (
            <main>

                <div className='head'>
                    <div>
                        <Header />
                    </div>
                    <div className="title" style={{ fontFamily: this.state.width < 799 ? 'MuseoSans_500!important' : 'Museo_300!important' }}>{ReactHtmlParser(this.state.homedesc)}</div>
                    <div className='home_title_image'>
                        <img className="d-block" src={hometitle} alt='hometitle' />
                    </div>
                </div>
                <div className='homepagediv'>

                    <div>
                        <div className='card_container homecards'>
                            {/* {this.state.button2?
                <div className="card card-body">
                    <div className="card-title secondcard">{this.state.button2.field_title.value}</div>
                        {this.state.button2.field_description[0].value?ReactHtmlParser(this.state.button2.field_description[0].value):''}
                    <Link to='/get_funded'>
                        {this.state.button2.field_button?<button className="btn">{this.state.button2.field_button.title}</button>:''}
                    </Link>
                </div>
                :<div></div>} */}
                            {this.state.button1 ?
                                <div className="card card-body ml-0">
                                    <div className="card-title">{this.state.button1.field_title.value}</div>
                                    {ReactHtmlParser(this.state.button1.field_description[0].value)}
                                    <Link to='/invest'>
                                        <button className="btn">{this.state.button1.field_button.title}</button>
                                    </Link>
                                </div> : <div></div>}
                            {this.state.button2 ?
                                <div className="card card-body mr-0">
                                    <div className="card-title secondcard">{this.state.button2.field_title.value}</div>
                                    {ReactHtmlParser(this.state.button2.field_description[0].value)}
                                    <Link to='/get_funded'>
                                        <button className="btn">{this.state.button2.field_button.title}</button>
                                    </Link>
                                </div>
                                : <div></div>}
                            <div>
                            </div>
                        </div>
                        <div className='milestone_main'>
                            <div className='milestone'>
                                <div className='milestone_img'>
                                    <img src={dealimage} alt='img' />
                                </div>
                                <div className='milestone_text'>
                                    <div className='milestone_text_number'>1500+</div>
                                    <div>Deals analysed</div>
                                </div>
                            </div>
                            <div className='milestone'>
                                <div className='milestone_img'>
                                    <img src={interestimg} alt='img' />
                                </div>
                                <div className='milestone_text'>
                                    <div className='milestone_text_number'>5000+</div>
                                    <div>Registered Investors</div>
                                </div>
                            </div>
                            <div className='milestone'>
                                <div className='milestone_img'>
                                    <img src={activeimg} alt='img' />
                                </div>
                                <div className='milestone_text'>
                                    <div className='milestone_text_number'>1200+</div>
                                    <div>Active investors</div>
                                </div>
                            </div>
                            <div className='milestone flex'>
                                <div className='milestone_img'>
                                    <img src={businessimg} alt='img' />
                                </div>
                                <div className='milestone_text'>
                                    <div className='milestone_text_number milestone_textleft'>150</div>
                                    <div>Business funded</div>
                                </div>
                            </div>
                            <div className='milestone flex'>
                                <div className='milestone_img'>
                                    <img src={croreimg} alt='img' />
                                </div>
                                <div className='milestone_text'>
                                    <div className='milestone_text_number milestone_textleft'>230</div>
                                    <div>Crores Raised</div>
                                </div>
                            </div>
                            {/* <div className='milestone'>
                    <div className='milestone_img'>
                        <img src={dealimage} alt='img'/>
                    </div>
                    <div className='milestone_text'>
                        <div className='milestone_text_number'>800+</div>
                        <div>Deals analysed</div>
                    </div>
                </div>
                
                <div className='milestone'>
                    <div className='milestone_img'>
                        <img src={businessimg} alt='img'/>
                    </div>
                    <div className='milestone_text'>
                        <div className='milestone_text_number'>75</div>
                        <div>Business funded</div>
                    </div>
                </div>
                <div className='milestone'>
                    <div className='milestone_img'>
                        <img src={croreimg} alt='img'/>
                    </div>
                    <div className='milestone_text'>
                        <div className='milestone_text_number'>45</div>
                        <div>Crores Raised</div>
                    </div>
                </div> */}
                        </div>

                    </div>

                    <div className='invest_business'>

                        <div className='contact_homepage'>
                            <div className="card-title">Business</div>
                            <div className='contact_homepage_div2'>
                                <div className="card-text">Learn how Rehbar creates value for  entrepreneurs.</div>
                                <Link to='/contact'>
                                    <button className="btn">Contact Us</button>
                                </Link>
                            </div>
                        </div>

                        {/* Deals executed */}

                        {/* <div className='deals_executed'>
                            <div className='card-title deal_title'>Deals Executed</div>

                            {this.state.slidercomp ? <Slider {...settings} >
                                {this.state.slidercomp ? this.state.slidercomp.map((slider, index) => {
                                    return (
                                        <div key={index} className='deals_carousel'>
                                            <img src={BASE + slider.attributes.uri.url} alt='img' />
                                        </div>)
                                }) : ''}


                            </Slider> : ''}

                        </div> */}

                    </div>

                    {/* Testimonial */}
                    {this.state.testimonial && this.state.testimg && this.state.testlogo ?

                        <div className='testimonials homtestimonial'>
                            <div className='card-title'>Testimonials</div>
                            {this.state.testimonial ?
                                <div className="testimonial_div">
                                    <div className='testimonial_header'>
                                        <div className='testimonial_header_div1'>
                                            <div>
                                                <img className='img-circle' src={this.state.imagearray ? this.state.imagearray[this.state.testindex] : dummy} height='120px' width='120px' alt='hometitle' />
                                            </div>
                                            <div className='profile'>
                                                <div className='card-title'>{this.state.testimonial[this.state.testindex].attributes.title}</div>
                                                {this.state.testimonial[this.state.testindex].attributes.field_designation ?
                                                    <div className="card-text"> {this.state.testimonial[this.state.testindex].attributes.field_designation.value}</div> : ''}
                                            </div>
                                        </div>
                                        <div className='quote'>
                                            <img src={quote} height='70px' alt='hometitle' />
                                        </div>
                                    </div>
                                    {this.state.testimonial[this.state.testindex].attributes.field_description ? <div className="testimonial_desc">
                                        {ReactHtmlParser(this.state.testimonial[this.state.testindex].attributes.field_description.value)}
                                    </div> : ''}
                                    <div className='testimonial_footer'>
                                        <div>
                                            {this.state.logoarray ? <img className='img-circle' src={this.state.logoarray ? this.state.logoarray[this.state.testindex] : white} height='30px' alt='hometitle' /> : ''}
                                        </div>
                                        <div className='testimonial_arrow'>
                                            <div className="cursor"><img onClick={() => this.testindex(1)} src={this.state.testindex === 0 ? deactiveleft : activeleft} alt='hometitle' /></div>
                                            <div className="cursor"><img onClick={() => this.testindex(2)} src={this.state.testindex === this.state.testlen - 1 ? deactiveright : activeright} alt='hometitle' /></div>
                                        </div>
                                    </div>
                                </div> : <div></div>}

                        </div> : ''}


                    {/* Recently funded */}

                    <div className='rec_fund'>
                        {this.state.slidercomp ? this.state.slidercomp.lenght !== 0 ?
                            <div className='deals_executed'>
                                <div className='card-title deal_title'>Recently Funded through Rehbar</div>

                                {this.state.slidercomp ? <Slider {...settings} >
                                    {this.state.slidercomp ? this.state.slidercomp.map((slider, index) => {
                                        return (
                                            <div key={index} className='deals_carousel'>
                                                <img src={BASE + slider.attributes.uri.url} alt='img' />
                                            </div>)
                                    }) : ''}


                                </Slider> : ''}

                            </div> : '' : ''}

                        <div className='apply_fund_homepage'>

                            <div className='apply_fund_homepage_button'>
                                <div className='card-title'>Apply for funding through Rehbar</div>
                                <Link to='/get_funded'>
                                    <button className="btn">Apply</button>
                                </Link>
                            </div>

                        </div>
                    </div>


                </div>

                <div className='footer_home'>
                    <Footer />
                </div>
            </main>
        )


    }

}

export default HomePage;
